import React, { useEffect } from "react";
import Reveal from "reveal.js";
import Markdown from "reveal.js/plugin/markdown/markdown.esm.js";
import "reveal.js/dist/reveal.css";
import "reveal.js/dist/theme/white.css";

import "./Slider.css";
// import SectionOne from "./sections/SectionOne";
// import SectionTwo from "./sections/SectionTwo";
// import SectionThree from "./sections/SectionThree";
// import SectionFour from "./sections/SectionFour";
// import SectionFive from "./sections/SectionFive";
// import SectionSix from "./sections/SectionSix";

const Slider = () => {
  useEffect(() => {
    const deck = new Reveal({
      plugins: [Markdown],
      //   autoSlide: 2000,
      //   loop: true,
      //   autoAnimateEasing: "ease-out",
      autoPlayMedia: true,
      //   autoAnimateUnmatched: false,
      //   embedded: true,
    });
    deck.initialize();
  }, []);

  return (
    <div className="slider">
      <div className="reveal">
        <div className="slides">
          <section
            data-background-video="images/video1.mp4"
            data-background-video-loop
            data-background-video-muted
            data-background-position
          ></section>
          
          {/* <section
            data-background-image="images/slide01.png"
            data-background-repeat="no-repeat"
            data-background-size="cover"
          >
            <SectionOne />
          </section>
          <section
            data-background-image="images/slide02.png"
            data-background-repeat="no-repeat"
            data-background-size="cover"
          >
            <SectionTwo />
          </section>
          <section
            data-background-image="images/slide03.png"
            data-background-repeat="no-repeat"
            data-background-size="cover"
          >
            <SectionThree />
          </section>
          <section
            data-background-image="images/slide04.png"
            data-background-repeat="no-repeat"
            data-background-size="cover"
          >
            <SectionFour />
          </section>
          <section
            data-background-image="images/slide03.png"
            data-background-repeat="no-repeat"
            data-background-size="cover"
          >
            <SectionFive />
          </section>
          <section
            data-background-image="images/slide06.png"
            data-background-repeat="no-repeat"
            data-background-size="cover"
          >
            <SectionSix />
          </section>  */}
        </div>
      </div>
    </div>
  );
};

export default Slider;

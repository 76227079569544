import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Main from "./components/Main/Main";
import Kalendar from "./components/Kalendar/Kalendar";
import Booking from "./components/Booking/Booking";
import { ChatProvider } from "./components/Chat/ChatContex";
import { ModalsProvider } from "./context/SuccessModal";

function App() {
  return (
    <Router>
      <ModalsProvider>
        <ChatProvider>
          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/kalendar" element={<Kalendar />} />
            <Route path="/booking" element={<Booking />} />
          </Routes>
        </ChatProvider>
      </ModalsProvider>
    </Router>
  );
}

export default App;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal{
   position: relative;
    width: 60%;
    height: 100%;
    background: #fff;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
    border-radius: 15px;
    top: -150px;
}
.modal__content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.modal__btn{
    padding: 17px 56px;
    color: #fff!important;
    border-radius: 0.5rem!important;
    background: #0276fd;
    height: 50px;
    max-width: 450px;
    font-size: 2rem;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    cursor: pointer;
}
.modal__btn:disabled,
.modal__btn.disabled {
  background: #0276fd;
  opacity: 0.5;
  cursor: not-allowed;
}`, "",{"version":3,"sources":["webpack://./src/components/Booking/Modal.css"],"names":[],"mappings":"AAAA;GACG,kBAAkB;IACjB,UAAU;IACV,YAAY;IACZ,gBAAgB;IAChB,aAAa;IACb,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,mBAAmB;IACnB,WAAW;AACf;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,WAAW;AACf;AACA;IACI,kBAAkB;IAClB,qBAAqB;IACrB,+BAA+B;IAC/B,mBAAmB;IACnB,YAAY;IACZ,gBAAgB;IAChB,eAAe;IACf,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,gBAAgB;IAChB,eAAe;AACnB;AACA;;EAEE,mBAAmB;EACnB,YAAY;EACZ,mBAAmB;AACrB","sourcesContent":[".modal{\n   position: relative;\n    width: 60%;\n    height: 100%;\n    background: #fff;\n    z-index: 1000;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    padding: 30px;\n    border-radius: 15px;\n    top: -150px;\n}\n.modal__content{\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    width: 100%;\n}\n.modal__btn{\n    padding: 17px 56px;\n    color: #fff!important;\n    border-radius: 0.5rem!important;\n    background: #0276fd;\n    height: 50px;\n    max-width: 450px;\n    font-size: 2rem;\n    border: none;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    margin-top: 50px;\n    cursor: pointer;\n}\n.modal__btn:disabled,\n.modal__btn.disabled {\n  background: #0276fd;\n  opacity: 0.5;\n  cursor: not-allowed;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

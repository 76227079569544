// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chat__select-time{
    width: 100%;
    height: 35px;
    margin-bottom: 12px;
    border-radius: 8px;
}`, "",{"version":3,"sources":["webpack://./src/components/Chat/TimeSlots.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,kBAAkB;AACtB","sourcesContent":[".chat__select-time{\n    width: 100%;\n    height: 35px;\n    margin-bottom: 12px;\n    border-radius: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

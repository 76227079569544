import React, { useContext, useEffect, useRef, useState } from "react";
import { getChatResponse } from "../utils/apiCalls";
import "./Chat.css";
import { useChatContext } from "./ChatContex";
import TimeSlotsPage from "./TimeSlots";
import { modalsContext } from "../../context/SuccessModal";

let InitalMessage = true;

const ChatMessageOverlay = ({ message, children, index }) => {
  return (
    <div
      key={index}
      className={`chat__message-box ${
        message.user === "bot" ? "bot__message-box" : "user__message-box"
      }`}
    >
      <div
        className={`chat__message-box ${
          message.user === "bot" ? "chat__wrappLogoUser" : "chat__wrappLogo"
        }`}
      >
        <div className="chat__sectionAvatar">
          {message.user === "bot" ? (
            <>
              {message.avatar === "default" && (
                <>
                  <img
                    src="images/pic02_no_bg.png"
                    alt="img"
                    className="bot__image"
                  />
                  <p className="chat__ownerBot">AI Assistant</p>
                </>
              )}
            </>
          ) : (
            <>
              <p className="chat__owner">You</p>
            </>
          )}
        </div>
        <div
          className={`chat-message ${
            message.user === "bot" ? "bot-message" : "user-message"
          }`}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

const Chat = () => {
  const [search, setSearch] = useState("");
  const [sessionId, setSessionId] = useState("");
  const { showSuccessModal } = useContext(modalsContext);
  const [renderData, setRenderData] = useState([]);
  //const [currentPage, setCurrentPage] = useState("Start Page");
  const { chatData, setChatData } = useChatContext();

  const [data, setData] = useState([]);

  const div = useRef(null);

  useEffect(() => {
    //console.log("show modal------->", showSuccessModal);

    setRenderData(
      chatData
        .slice()
        .reverse()
        .map((message, index) => {
          if (message.type === "text") {
            return (
              <ChatMessageOverlay key={index} message={message} index={index}>
                <div className={`chat--message-text ${
            message.user === "bot" ? "bot-text" : "user-text"
          }`}>{message.text}</div>
              </ChatMessageOverlay>
            );
          } else if (message.type === "text-select") {
            return (
              <>
                {showSuccessModal && (
                  <ChatMessageOverlay
                    key={index}
                    message={message}
                    index={index}
                  >
                    <div style={{display: 'flex'}}>
                      <img
                        src="images/pic02_no_bg.png"
                        alt="img"
                        className="bot__image"
                      />
                      <p style={{fontWeight: 'bold', fontSize: '19px'}}>Bot</p>
                    </div>
                    <div className="chat__message-text">
                      Thanks for your time!
                    </div>
                  </ChatMessageOverlay>
                )}
                <ChatMessageOverlay key={index} message={message} index={index}>
                  <div className="chat__message-text">{message.text}</div>
                  <TimeSlotsPage data={data} />
                </ChatMessageOverlay>{" "}
              </>
            );
          } else {
            return null;
          }
        })
    );
  }, [data, chatData, showSuccessModal]);

  useEffect(() => {
    // console.log("Inital message");
    if (InitalMessage) {
      handleSendMessage('', true);
      // const urlParams = new URLSearchParams(window.location.search);

      // const firstName = urlParams.get("firstname") || "User";

      // const lastName = urlParams.get("lastname") || " ";

      // const companyName = urlParams.get("companyname") || "Your Company";

      // // console.log("name", firstName, lastName, companyName);

      // if (firstName && lastName && companyName) {
      //   handleSendMessage(
      //     `Hello my first name is ${firstName}, my last name is ${lastName}, and i work in ${companyName}`,
      //     true
      //   );
      // }
      InitalMessage = false;
    }
  });

  useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'childList') {
          // Scroll to bottom whenever a child node is added to the chat box
          const chatBox = div.current;
          if (chatBox) {
            chatBox.scrollTop = chatBox.scrollHeight;
          }
        }
      });
    });
  
    // Configuration of the observer:
    const config = { childList: true };
  
    // Start observing the chat box for configured mutations
    if (div.current) {
      observer.observe(div.current, config);
    }
  
    // Clean up the observer on component unmount
    return () => observer.disconnect();
  }, []); // Empty dependency array ensures this runs once on mount
  

  const onSearchChange = ({ target }) => {
    setSearch(target.value);
  };

  const handleSendMessage = async (input, isInitial = false) => {
    const userMessage = input;
    setSearch("");

    if (!isInitial) {
      setChatData((prev) => {
        const newArr = [
          ...prev,
          { user: "user", text: userMessage, type: "text" },
        ];

        return newArr;
      });
    }

    const result = await getChatResponse(userMessage, sessionId);

    const { data, sessionId: sessionIdFromServer, currentPage } = result;
    // console.log("check data", result);
    //setCurrentPage(currentPage ? currentPage : null);
    if (sessionIdFromServer !== sessionId) {
      setSessionId(sessionIdFromServer);
    }
    for (const message of data) {
      if (message) {
        setChatData((prev) => {
          const newArr = [...prev];

          let avatar;

          //console.log(currentPage);

          switch (currentPage) {
            case "Confirm Appointment Intent":
            default:
              avatar = "default";
              break;
            case "Appointment":
          }

          if (currentPage === "Appointment") {
            newArr.push({
              user: "bot",
              text: message.text,
              avatar,
              type: "text-select",
            });
          } else if (message.type === "text") {
            newArr.push({
              user: "bot",
              text: message.text,
              avatar,
              type: "text",
            });
          } else if (message.type === "image") {
            newArr.push({
              user: "bot",
              url: message.url,
              avatar,
              type: "image",
            });
          }

          const schedule = result.user_fields?.schedule?.listValue.values;

          if (schedule) {
            const parsedSchedule = schedule.map((item) => {
              const fields = item.structValue.fields;
              const day = fields.day.stringValue;
              const hours = fields.hours.listValue.values.map(
                (item) => item.stringValue
              );
              return { day, hours };
            });

            setData(parsedSchedule);
            // console.log("LOgo", parsedSchedule);
          }
          return newArr;
        });
      }
    }
  };

  const onEnterPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevents default form submission
  
      // Trim the search input and check if it's not empty
      const trimmedSearch = search.trim();
      if (trimmedSearch) {
        handleSendMessage(trimmedSearch);
        // Clear the input field if necessary here, depending on how handleSendMessage works
        // setSearch("");
        
        // Blur the input field to hide the keyboard on mobile devices
        event.target.blur();
      }
    }
  };
  

  return (
    <div className="chat">
      <div className="chat__title">
        <p>Start Your AI Conversation Journey Here</p>
        <img
          src="images/qr_code.png"
          alt="qr_code"
          className={`qr_code ${chatData.length > 1 ? 'hidden' : ''}`}
        />
      </div>
      <div className="chat__content">
        <div className="chat-container">
          <div ref={div} className="chat-box">
            {renderData}
          </div>
        </div>
        <div className="chat__sendWrapp">
          <input
            className="chat-input"
            type="text"
            placeholder="Type your response here..."
            value={search}
            onChange={onSearchChange}
            onKeyDown={onEnterPress}
          />
        </div>
      </div>
    </div>
  );
};

export default Chat;

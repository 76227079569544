import { BadgeCheck } from 'lucide-react';
import React from 'react';
import './Modal.css';

const SuccessModal = ({ onClose }) => {
  return (
    <div className="modal">
      <div className="modal__content">
      <BadgeCheck color="#42b72a" strokeWidth={4} fontSize={55} />
        <p>Your invitation was successfully sent!</p>
        <button onClick={onClose} className='modal__btn'>Close</button>
      </div>
    </div>
  );
};

export default SuccessModal;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./Kalendar.css";
import { CalendarCheck } from "lucide-react";

const Kalendar = () => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [availableTimeSlots, setAvailableTimeSlots] = useState([]);
  const [day, setDay] = useState([]);
  const [showThankYou, setShowThankYou] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Retrieve sortedSchedule from local storage
    const storedSortedSchedule = localStorage.getItem("sortedSchedule");

    if (storedSortedSchedule) {
      const parsedSortedSchedule = JSON.parse(storedSortedSchedule);
      // console.log("Sorted Schedule from Local Storage:", parsedSortedSchedule);

      // Find the index of the array that matches the selected date
      if (selectedDate) {
        const selectedDay = formatCustomDate(selectedDate); // Convert to "MM/DD/YYYY" format

        let foundIndex = -1;
        for (let i = 0; i < parsedSortedSchedule.length; i++) {
          if (
            formatCustomDate(new Date(parsedSortedSchedule[i].day)) ===
            selectedDay
          ) {
            foundIndex = i;
            break;
          }
        }

        if (foundIndex !== -1) {
          const selectedDayData = parsedSortedSchedule[foundIndex];
          const selectedDayHours = selectedDayData.hours;
          const selectedDayIndex = selectedDayData.day;
          //   console.log(
          //     "Index of Selected Date in sortedSchedule:",
          //     selectedDayIndex
          //   );
          //   console.log("Hours for Selected Date:", selectedDayHours);
          // Now you can use selectedDayIndex and selectedDayHours as needed in your component
          setAvailableTimeSlots(selectedDayHours);
          setDay(selectedDayIndex);
        } else {
          //   console.log("Selected date not found in sortedSchedule.");
          setAvailableTimeSlots([]); // Reset availableTimeSlots if selected date not found
        }
      }
    }
  }, [selectedDate]);

  // console.log("availableTimeSlots", availableTimeSlots);
  const onChange = (date) => {
    // Handle the selected date change here
    setSelectedDate(date);
    // console.log("Selected Date:", date);
  };

  const isDateDisabled = (date) => {
    return selectedDate && date < new Date(selectedDate);
  };

  const formatCustomDate = (date) => {
    const options = {
      weekday: "short",
      month: "short",
      day: "numeric",
    };

    const formattedDate = new Date(date).toLocaleDateString("en-US", options);
    // const dayNucmber = new Date(date).getDate();
    // const daySuffix = getDaySuffix(dayNumber);

    return `${formattedDate}`;
  };
  const [selectedBooking, setSelectedBooking] = useState(null);
  const handleBookingClick = (selectedDay, selectedTime) => {
    // Save the selected booking time to local storage
    localStorage.setItem("selectedBookingDay", selectedDay);
    localStorage.setItem("selectedBookingTime", selectedTime);
    setSelectedBooking(`${selectedDay} ${selectedTime}`);
    setShowThankYou(true);
    setTimeout(() => {
      setShowThankYou(false);
      // Navigate to the booking page
      navigate("/booking");
    }, 2000);
  };

  return (
    <div className="calendar">
      <div>
        <Calendar
          onChange={onChange}
          value={selectedDate}
          tileDisabled={({ date }) => isDateDisabled(date)}
          className="kalendarCustomClass"
        />
      </div>
      <div className="calendar__bookItemWrapp">
        {availableTimeSlots.length > 0 && (
          <>
            {availableTimeSlots.map((time, index) => (
              <div
              className={`calendar__bookItem ${selectedBooking === `${formatCustomDate(day)} ${time}` ? 'calendar__bookItem--selected' : ''}`}
                key={index}
                onClick={() => handleBookingClick(formatCustomDate(day), time)}
              >
                <CalendarCheck color="#fff" className="calendar__icon" /> {"  "}{formatCustomDate(day)} {time}
              </div>
            ))}
          </>
        )}
        
      </div>
      {showThankYou && (
          <div className="calendar__book">
            {" "}
            <CalendarCheck color="#006edc" /> {"  "}<p className="calendar__book-title">BOOKED</p>
          </div>
        )}
    </div>
  );
};

export default Kalendar;

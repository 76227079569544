import { createContext, useState } from "react";

export const modalsContext = createContext();

export function ModalsProvider({ children }) {
    const [showSuccessModal, setShowSuccessModal] = useState(false);

  return (
    <modalsContext.Provider
      value={{
        showSuccessModal,
        setShowSuccessModal,
      }}
    >
      {children}
    </modalsContext.Provider>
  );
}

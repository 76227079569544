import React from 'react'
import Slider from '../Slider/Slider'
import Chat from '../Chat/Chat'

const Main = () => {
  return (
    <div className="wrapp">
    <Slider />
    <Chat />
  </div>
  )
}

export default Main
import React, { useContext, useState } from "react";
import "./Booking.css";
import { CalendarCheck } from "lucide-react";
import axios from "axios";
import SuccessModal from "./Modal";
import { useNavigate } from "react-router-dom";
import { modalsContext } from "../../context/SuccessModal";

const Booking = () => {
  const selectedDate = localStorage.getItem("selectedBookingDay");
  const selectedTime = localStorage.getItem("selectedBookingTime");
  // const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const { showSuccessModal, setShowSuccessModal } = useContext(modalsContext);
  const [formData, setFormData] = useState({
    date: selectedDate,
    email: "",
    time: selectedTime,
    phone: "",
    meetingPurpose: "",
  });
  const [errors, setErrors] = useState({
    email: "",
    phone: "",
    meetingPurpose: "",
  });
  //const [isMeetingPurposeEmpty, setIsMeetingPurposeEmpty] = useState(false);
  const navigate = useNavigate();
  // const {selectedHours, selectedTime} = useBookingContext();
  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({ ...prevData, [name]: value }));

    // Basic email validation
    if (name === "email") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: emailRegex.test(value) ? "" : "Invalid email address",
      }));
    }

    // Basic phone validation (allows numbers, spaces, and dashes)
    if (name === "phone") {
      const phoneRegex = /^[+0-9\s-]+$/;
      setErrors((prevErrors) => ({
        ...prevErrors,
        phone: phoneRegex.test(value) ? "" : "Invalid phone number",
      }));
    }
    if (name === "meetingPurpose") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        meetingPurpose: value ? "" : "Please select a meeting purpose",
      }));
    }
    const isEmailEmpty = formData.email.trim() === "";
    const isPhoneEmpty = formData.phone.trim() === "";
    const isMeetingPurposeEmpty = !formData.meetingPurpose;
    //setIsMeetingPurposeEmpty(isMeetingPurposeEmpty);
    setIsButtonDisabled(isEmailEmpty && isPhoneEmpty && isMeetingPurposeEmpty);
  };
  //const location = useLocation();
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check for errors before submitting
    if (errors.email || errors.phone) {
      //console.log("Form has errors. Please fix them before submitting.");
      return;
    }

    try {
      await axios.post(
        "https://ai-chat-demo-backend-5ky6pp3tgq-uc.a.run.app/personal/appointment",
        formData
      );
      //console.log("Email sent:", response.data);
      setShowSuccessModal(true);
      setFormData({
        date: "",
        email: "",
        time: "",
        phone: "",
        meetingPurpose: "",
      });

      setTimeout(() => {
        navigate("/");
        // window.location.reload();
      }, 2000);
    } catch (error) {
      console.error("Error sending email:", error);
    }
  };

  const closeModal = () => {
    // Close the success modal
    setShowSuccessModal(false);
  };
  // console.log('showSuccessModal:', showSuccessModal);
  return (
    <div className="calendar">
      <h2>PLEASE CONFIRM YOUR SLOT</h2>
      <div className="book">
        <CalendarCheck color="#0276FD" className="calendar__icon" />
        <h3 className="book__title">
          {selectedDate} {selectedTime}
        </h3>
      </div>
      <form onSubmit={handleSubmit} className="book__form">
        <div className="book__fields">
          <div className="book__fields-email">
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              className="book__input"
              placeholder="Your Email.."
            />
            {errors.email && <p className="error">{errors.email}</p>}
          </div>
        </div>

        <button
          type="submit"
          className={`modal__btn ${isButtonDisabled ? "disabled" : ""}`}
          disabled={isButtonDisabled}
        >
          Confirm
        </button>
      </form>
      {showSuccessModal && (
        <SuccessModal onClose={closeModal} isOpen={showSuccessModal} />
      )}
    </div>
  );
};

export default Booking;

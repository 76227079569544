import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./TimeSlots.css"

const formatDateTime = (dateTimeString) => {
  const options = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  const formattedDateTime = new Date(dateTimeString).toLocaleString(
    "en-US",
    options
  );
  return formattedDateTime;
};

export const TimeSlotsPage = ({ data }) => {
  // console.log("time slots component", data);

  const sortedSchedule = data.sort((a, b) => new Date(a.day) - new Date(b.day));
  // console.log("sortedSchedule", sortedSchedule[0]?.structValue?.fields);
  const closestDates = sortedSchedule.slice(0, 3);

  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedHours, setSelectedHours] = useState(null);
  const navigate = useNavigate();

  const handleTimeSelect = (time) => {
    setSelectedTime(time);
  };

  const handlerBook = () => {
    // Save sortedSchedule to local storage
    localStorage.setItem("sortedSchedule", JSON.stringify(sortedSchedule));
    localStorage.setItem("selectedBookingDay", selectedTime);
    localStorage.setItem("selectedBookingTime", selectedHours);

    // console.log("selectedHours--->>>TIMEEE", selectedHours);
    // console.log("selectedTime--->>>", selectedTime);
    const selectedDate = new Date(selectedHours);

    selectedDate?.setHours(selectedHours?.split(":")[0]);

    selectedDate?.setMinutes(selectedHours?.split(":")[1]);

    navigate(`/booking?selectedTime=${encodeURIComponent(selectedHours)}`);
  };

  const handleShowMore = () => {
    // Save sortedSchedule to local storage
    localStorage.setItem("sortedSchedule", JSON.stringify(sortedSchedule));

    navigate(`/kalendar`);
  };
  return (
    <div>
      {closestDates.map((slot, index) => (
        <div key={index} className="chat__help-days">
          {selectedTime === formatDateTime(slot?.day) ? (
            <>
              <p
                onClick={() =>
                  handleTimeSelect(formatDateTime(slot?.day), index)
                }
              >
                {formatDateTime(slot?.day)}
              </p>
              <select onChange={(e) => setSelectedHours(e.target.value)} className="chat__select-time">
                <option value="" disabled selected>
                  Select a time
                </option>
                {slot?.hours?.map((hour, hourIndex) => (
                  <option key={hourIndex} value={`${hour}`}>
                    {hour}
                  </option>
                ))}
              </select>
              {selectedHours && (
                <div onClick={handlerBook}>Book in Calendar</div>
              )}
            </>
          ) : (
            <p
              onClick={() => handleTimeSelect(formatDateTime(slot?.day), index)}
            >
              {formatDateTime(slot.day)}
            </p>
          )}
        </div>
      ))}
      <div onClick={handleShowMore} className="chat__help-days">
        SHOW TIME SLOTS
      </div>
    </div>
  );
};

export default TimeSlotsPage;
